<template>
  <div class="user-management-page">
    <navigation></navigation>

    <div class="dashboard-container-right">
      <h2 class="title mt-4">User Management</h2>
      <hr />
      <div v-if="!users" class="loader-dashboard-page"><div></div></div>
      <div class="users-wrapper" v-else>
        <!-- SEARCH -->
        <div class="filter-wrappers" style="overflow: hidden">
          <label>Search User:</label>
          <div style="display: flex">
            <form @submit.prevent="search()" style="display: flex">
              <input
                class="form-control"
                placeholder="Search by name, last name, company name, email"
                autocorrect="none"
                autocapitalize="none"
                name="login"
                v-model.trim="searchQuery"
                type="search"
                size="60"
                maxlength="60"
              />
              <input type="submit" value="Search" class="ml-2 button btn btn-info" />
            </form>
            <transition name="slide-from-right">
              <button v-if="showClear" class="btn ml-2 btn-sm" @click="clearSearch()">Reset</button>
            </transition>
          </div>
        </div>
        <!-- USER LIST -->
        <ul class="orders-list-ul">
          <transition-group :name="animationClass">
            <li v-for="user in users" :key="user.id">
              <a @click.prevent="selectUser(user.id)" href="#" v-bind:class="'user-' + user.id">
                <div class="pull-left pull-left-items">
                  <div class="details">
                    <div><strong>Contact person:</strong> {{ user.first_name }} {{ user.last_name }}</div>
                    <div><strong>Company: </strong> {{ user.company_name }}</div>
                    <div><strong>Username/email: </strong> {{ user.email }}</div>
                  </div>
                </div>
                <div class="pull-right pull-right-items">
                  <div
                    v-if="user.email_verified_at"
                    style="font-size: 12px; font-weight: 600; text-transform: uppercase; text-align: right"
                    class="text-success"
                  >
                    <strong>Verified</strong>
                  </div>
                  <div v-else style="font-size: 12px; font-weight: 600; text-transform: uppercase; text-align: right" class="text-danger">
                    <strong>Not Verified</strong>
                  </div>
                  <div
                    v-for="role in user.user_roles"
                    :key="role.id"
                    style="font-size: 12px; font-weight: 600; text-transform: uppercase; text-align: right"
                    :style="role.name == 'lab_admin' ? 'color: #09aef4' : role.name == 'admin' ? 'color: #0084B6' : 'color:grey'"
                  >
                    {{ helpers.slugToText(role.name) }}
                  </div>
                </div>
                <div class="clearfix"></div>
              </a>
            </li>
          </transition-group>
        </ul>

        <!-- PAGER -->
        <div class="pager">
          <nav v-if="usersCount > pager.pagerDisplayCount">
            <ul class="pagination pull-right">
              <li class="page-item">
                <button class="page-link btn btn-default" @click.prevent="pagerBack()" :disabled="pager.currentPage === 1">
                  <i class="glyphicon glyphicon-chevron-left"></i>
                </button>
              </li>

              <li class="page-item">
                <button class="page-link btn btn-default" @click.prevent="pagerNext()" :disabled="pager.currentPage === pager.pagerLinks">
                  <i class="glyphicon glyphicon-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
          <div class="clearfix"></div>
          <div class="pager-text-wrapper">
            <div class="pull-right pager-text">
              <span>{{ fromPager }} - {{ toPager }}</span> of <span>{{ usersCount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="users && users.length == 0" style="text-align: center">No users found</div>
    </div>
  </div>
</template>

<script>
import Navigation from '../../../generic/Navigation.vue';
import { mapGetters } from 'vuex';
import * as helpers from '@/helpers/functions.js';

export default {
  name: 'User',
  components: { Navigation },
  data() {
    return {
      initialPagerCount: 15,
      users: null,
      usersCount: null,
      searchQuery: null,
      pager: {
        currentPage: 1,
        pagerLinks: null,
        pagerDisplayCount: null
      },
      animationClass: 'fade',
      showClear: false,
      helpers
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    }),
    fromPager() {
      if (!this.pager.currentPage) return null;
      let fromPager;
      fromPager = this.pager.currentPage * this.pager.pagerDisplayCount - this.pager.pagerDisplayCount + 1;
      return fromPager;
    },
    toPager() {
      let toPager;
      toPager = this.pager.currentPage * this.pager.pagerDisplayCount;
      if (toPager > this.usersCount) return this.usersCount;
      else return toPager;
    }
  },
  created() {
    this.pager.pagerDisplayCount = this.initialPagerCount;
    this.getUsers();
  },

  methods: {
    async getUsers() {
      if (this.users?.length > 0) this.$store.commit('setTransLoader', true);
      return this.$store
        .dispatch('getUsers', {
          searchQuery: this.searchQuery,
          currentPage: this.pager.currentPage,
          pagerDisplayCount: this.pager.pagerDisplayCount
        })
        .then(data => {
          this.users = data.users;
          this.usersCount = data.total;
          this.setPager();
        })
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    setPager() {
      let count = this.usersCount;
      let displayCount = this.pager.pagerDisplayCount;
      let remainderLinks = Math.round(parseInt(count) % parseInt(displayCount));
      let lastPagerLink = 0;

      if (remainderLinks > 0) lastPagerLink = 1;
      this.pager.pagerLinks = Math.floor(parseFloat(count) / displayCount) + lastPagerLink;
    },
    pagerNext() {
      this.pager.currentPage++;
      this.getUsers();
    },
    pagerBack() {
      this.pager.currentPage--;
      this.getUsers();
    },
    search() {
      this.resetPager();
      this.getUsers().then(() => (this.showClear = true));
    },
    clearSearch() {
      this.showClear = false;
      this.searchQuery = null;
      this.getUsers();
    },
    resetPager() {
      this.pager = {
        currentPage: 1,
        pagerLinks: null,
        pagerDisplayCount: this.initialPagerCount
      };
    },
    selectUser(userId) {
      this.$router.push('/manage-users/' + userId);
    }
  }
};
</script>

<style lang="scss"></style>
